<template>
  <div>
    <div id="index">
      <contentHeader :title="title" @input="setFilter" />

      <q-markup-table wrap-cells dense>
        <thead>
          <tr>
            <th>Created</th>
            <th>Name</th>
            <th>Is published</th>
            <th>License</th>
            <th>Partner</th>
            <th>Member</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="k" v-for="(c, k) in filteredCollections">
            <td>
              {{ d(c.created) }}
            </td>
            <td>
              {{ c.title }}
            </td>
            <td v-html="is_published(c.is_published)"></td>
            <td>
              {{ c.license ? c.license.code : "&mdash;" }}
            </td>
            <td>
              <span v-if="c.partner">{{ c.partner.name }}</span>
            </td>
            <td>
              <span v-if="c.member">{{ c.member.name }}</span>
            </td>
            <td align="right" valign="bottom">
              <q-btn
                type="a"
                :href="getLink(c)"
                target="_new"
                color="primary"
                flat
                round
                dense
                icon="fad fa-eye"
              />
              <q-btn
                v-show="false"
                @click="cEdit(c.uuid)"
                color="primary"
                flat
                round
                dense
                icon="fad fa-pencil"
              />
              <q-btn
                @click="cDelete(c.uuid)"
                color="warning"
                flat
                round
                dense
                icon="fad fa-trash"
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
  </div>
</template>

<script>
import contentHeader from "@/admin/components/contentHeader";

import HiLicense from "@/components/hiLicense";
import hSelect from "@/components/hSelect";

import { humanDate } from "../tic.js";

export default {
  name: "admin-narratives",
  props: ["showAdd", "title"],
  components: {
    HiLicense,
    hSelect,
    contentHeader,
  },

  computed: {
    filteredCollections: function () {
      if (this.search) {
        return this.collections.filter((e) =>
          JSON.stringify(e).toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.collections;
    },
  },

  methods: {
    setFilter(search) {
      this.search = search;
    },

    is_published(val) {
      return val ? `<span class="checkmark">&check;</span>` : "&mdash;";
    },

    d(ts) {
      return humanDate(ts);
    },

    close() {
      this.$emit("hideAdd");
      this.showDialog = false;
    },

    loadData() {
      console.log("** LOAD DATA");
      let vm = this;
      this.$API
        .get("/admin/viewpoints")
        .then((r) => {
          this.collections = r.data.data;
          this.state = "index";
          vm.$forceUpdate();
          //this.isChanged = false
        })
        .catch((e) => {
          console.log("LOAD DATA ERROR: ", e);
        });
    },

    getLink(c) {
      // get link within context of current server
      let me = document.location.origin.toString();
      return ""; // ${me}/narratives/${c.slug}/start/edit`;
    },

    cDelete(e) {
      // DELETE A NARRATIVE
      this.$q
        .dialog({
          title: "Warning",
          message: "This will permanently delete the narrative. Continue?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          // TODO: sent session or user to validate on server side if delete is permitted
          this.isChanged = false;
          this.$API
            .delete("/admin/viewpoints/", {
              data: { uuid: e, user: "user" },
            })
            .then((r) => {
              this.loadData();
              //this.$q.notify({message: 'Delete complete', position: 'center', color:'primary'})
            });
        })
        .onCancel(() => {
          this.$q.notify({
            message: "Delete canceled",
            position: "center",
            color: "primary",
          });
        });
    },
  },

  mounted() {
    console.log("** MOUNT Collections.vue");
    this.loadData();
  },

  data() {
    return {
      search: "",
      mode: null,
      showDialog: false,
      isChanged: false,
      copyrightOptions: [],
      state: "index",
      current: null,
      record: {},
      collections: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.iconic-image {
  width: 200px;
  height: 200px;
  border: 2px lightgrey dotted;
}

td {
  vertical-align: top;
}

.center {
  display: flex;
  align-items: center;
}

tr:nth-child(even) {
  background-color: #eee;
}

th {
  text-align: left;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  color: grey;
}

.checkmark {
  color: green !important;
  font-size: 1.2em !important;
}
</style>
